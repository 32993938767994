import React, { useEffect, useState } from 'react';
import { useApi } from '../api';
import { StateLookupRequest, STATE_LOOKUP_RESPONSE } from '../api/types';
import { getAllCommercialProducts } from '../config/product-config';
import { CommercialProduct } from '../config/types/product-config-settings';
import { digitsOnly } from '../lib/text-helpers';
import { useClientConfigs } from '../contexts/ClientConfigContext';

export interface IAutoRedirectContext {
    zipCode: string;
    stateCode: string;
    product: CommercialProduct | undefined;  
    soa: string;   
    sourceCorrelationId: string;
    isAutoRedirect: boolean;
    verificationDone: boolean;
    skipProductSelection: boolean;
}

export type AutoRedirectContextProviderProps = {
    children?: React.ReactElement    
};

const AutoRedirectContext = React.createContext<IAutoRedirectContext>({
    zipCode: "",
    stateCode: "",
    product: undefined,
    soa: "",
    sourceCorrelationId: "",
    isAutoRedirect: false,
    verificationDone: false,
    skipProductSelection: false
});

const CanSkipProductSelectionPage = (product: CommercialProduct | undefined): boolean => {
    return product?.lobCode === "CAU"    
}

const AutoRedirectContextProvider: React.FC<AutoRedirectContextProviderProps> = ({ children }) => {

    const api = useApi();    
    const commercialProducts = getAllCommercialProducts();
    let queryString = window.location.search?.toLowerCase();
    const urlParams = new URLSearchParams(queryString.toLowerCase());
    const clientConfigs = useClientConfigs();

    //Init RedirectContext Data
    const [redirectContext, setRedirectContext] = useState<IAutoRedirectContext>(
        {
            zipCode: "",
            stateCode: "",
            product: undefined,
            soa: urlParams?.get('soa')??"",
            sourceCorrelationId: urlParams?.get('correlationid')??"",
            isAutoRedirect: false,
            verificationDone: false,
            skipProductSelection: false
        });    

    useEffect(() => {
      //  let queryString = window.location.search?.toLowerCase();
       // const urlParams = new URLSearchParams(queryString.toLowerCase());
        let urlZip = urlParams?.get('zipcode');
        let urlProduct = urlParams?.get('product');        
        let urlLob = urlParams?.get('lob');

        urlZip = digitsOnly(urlZip ? urlZip : "");
        let selectedProduct: CommercialProduct | undefined = undefined;

        if (urlProduct && commercialProducts.find(p => p.lobCode.toLowerCase() === urlProduct)) {
            selectedProduct = commercialProducts.find(item => item.lobCode.toLowerCase() === urlProduct?.toLowerCase());
        }

        if (urlLob && commercialProducts.find(p => p.sspCode.toLowerCase() === urlLob)) {
            selectedProduct = commercialProducts.find(item => item.sspCode.toLowerCase() === urlLob?.toLowerCase());
        }  

        if (urlZip.toLowerCase().length === 5) {
            let request: StateLookupRequest = { zipCode: urlZip };
            api.commercialAgentApi.stateLookup(request).then((zipStateResponse) => {
                if (zipStateResponse.type === STATE_LOOKUP_RESPONSE && zipStateResponse.data.success) {
                    if (clientConfigs.cauDisabledState?.includes(zipStateResponse.data.state) && selectedProduct?.lobCode === "CAU") {
                        setRedirectContext({
                            ...redirectContext,
                            zipCode: urlZip?.length === 5 ? urlZip : "",
                            stateCode: zipStateResponse.data.state,
                            product: undefined,
                            isAutoRedirect: false,
                            verificationDone: true,
                            skipProductSelection: false
                        });
                        return;
                    }
                    setRedirectContext({
                        ...redirectContext,
                        zipCode: urlZip?.length === 5 ? urlZip : "",
                        stateCode: zipStateResponse.data.state,
                        product: selectedProduct, 
                        isAutoRedirect: zipStateResponse.data.success && selectedProduct ? true : false,
                        verificationDone: true,
                        skipProductSelection: CanSkipProductSelectionPage(selectedProduct)
                    });
                }
                else {
                    setRedirectContext({
                        ...redirectContext,
                        zipCode: "",
                        stateCode: "",
                        product: selectedProduct,
                        isAutoRedirect: false,
                        verificationDone: true
                    });
                }
            }, (error) => {
                console.error(error);
                setRedirectContext({
                    ...redirectContext,
                    zipCode: "",
                    stateCode: "",
                    product: selectedProduct,  
                    isAutoRedirect: false,
                    verificationDone: true
                });

            });
        }
        else {
            setRedirectContext({
                ...redirectContext,
                isAutoRedirect: false,
                product: selectedProduct,
                verificationDone: true,
                skipProductSelection: CanSkipProductSelectionPage(selectedProduct)
            });
        }
    }, [clientConfigs]);

    console.log('Auto Redirect Context');
    return (
        <AutoRedirectContext.Provider value={redirectContext}>
            {children}
        </AutoRedirectContext.Provider>
    );
};

export { AutoRedirectContextProvider, AutoRedirectContext }

export const useAutoRedirectContext = (): IAutoRedirectContext => {
    return React.useContext(AutoRedirectContext);
}